import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'

const About = () => {
  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters index={15} stringArray={'About me'.split('')} />
          </h1>
          <p>
            Hey there 👋<br></br>
            I'm Vinn Njeru, a curiosity-driven fullstack mobile & web developer
            based in Nairobi, Kenya. I specialise in accessibility, performance
            and usability without sacrificing creativity.
          </p>
          <p>
            With my expertise on both frontend & backend technologies combined
            with creative problem solving skills, I strive to develop innovative
            web and mobile app solutions that are tailored specifically for each
            client's needs
          </p>
          <p>
            Beyond coding, I'm happiest when I'm going on travel adventures,
            outdoor activities, playing PUBG and TV series...and listening to
            all sorts of music 🎧
          </p>
          <a
            className="get-in-touch"
            href="mailto:dev@vinnjeru.com?subject=🤘 Hi Vinn, I'd like to hire you"
          >
            Say hi...
          </a>
        </div>
      </div>
      <Loader type="line-scale" />
    </>
  )
}

export default About
