import './index.scss'
import LogoVN from '../../assets/images/vinn-logo-initial.png'
import anime from 'animejs'
const Logo = () => {
  var logoAnimation = anime({
    targets: '.line-drawing .lines path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 2000,
    delay: function (el, i) {
      return i * 250
    },
    direction: 'alternate',
    loop: false,
  })
  logoAnimation.finished.then(() => {
    document.getElementById('solid-logo').classList.add('full-opacity')
  })

  return (
    <div className="logo-container">
      <img
        className="solid-logo"
        id="solid-logo"
        src={LogoVN}
        alt="vinn-initial"
      />

      <div className="line-drawing">
        <svg viewBox="0 0 700.000000 604.000000">
          <g
            className="svg-container lines"
            transform="translate(0.000000,604.000000) scale(0.100000,-0.100000)"
            fill="none"
            stroke="none"
          >
            <path
              className="el"
              d="M5025 4270 c-560 -974 -1020 -1770 -1024 -1770 -5 0 -1069 1838
-1536 2652 -49 87 -93 158 -96 158 -4 0 -72 -114 -152 -252 -80 -139 -337
-586 -572 -993 -235 -407 -536 -929 -670 -1160 -640 -1109 -957 -1658 -968
-1676 -10 -16 0 -42 67 -176 127 -256 249 -455 410 -667 66 -87 313 -379 325
-384 4 -1 23 26 42 60 20 35 194 340 389 678 194 338 492 856 661 1150 169
294 365 636 436 759 70 123 133 224 138 224 5 0 189 -310 407 -689 219 -379
588 -1019 820 -1421 321 -557 425 -730 434 -720 6 6 142 239 302 517 159 278
380 660 489 850 110 190 329 570 488 845 354 614 1552 2690 1572 2726 15 25
13 29 -73 150 -94 132 -196 257 -328 403 -79 87 -495 479 -527 496 -14 8 -180
-275 -1034 -1760z m1109 1657 l68 -62 -1033 -1792 c-569 -986 -1037 -1793
-1040 -1793 -4 0 -30 41 -59 90 l-52 90 24 43 c14 23 302 526 643 1117 340
591 782 1361 983 1710 370 644 380 660 391 660 3 0 37 -29 75 -63z m159 -159
c105 -91 324 -352 446 -531 28 -41 32 -53 23 -72 -6 -12 -322 -564 -703 -1226
-381 -662 -765 -1330 -853 -1484 -656 -1144 -1212 -2105 -1217 -2105 -4 0 -72
114 -152 252 -80 139 -266 462 -414 718 -147 256 -443 770 -658 1143 -214 372
-393 677 -396 677 -3 0 -53 -82 -111 -182 -468 -815 -1572 -2725 -1579 -2733
-12 -12 -34 15 -137 165 -116 168 -185 284 -278 471 l-84 166 338 589 c557
970 802 1397 1057 1839 133 231 385 670 560 975 175 305 323 563 329 572 7 13
7 21 0 28 -8 8 -41 -40 -117 -172 -100 -174 -1039 -1808 -1527 -2658 -124
-217 -326 -569 -448 -781 l-222 -387 26 -58 c14 -33 41 -89 60 -126 18 -38 32
-68 29 -68 -7 0 -112 192 -170 311 l-57 116 398 689 c218 379 485 842 594
1029 108 187 452 784 766 1327 431 748 572 984 580 975 6 -7 115 -192 241
-412 295 -513 1333 -2313 1424 -2469 38 -65 69 -130 69 -144 0 -16 4 -22 9
-17 5 6 262 449 572 985 745 1292 802 1392 1179 2045 354 615 348 605 356 605
4 0 34 -24 67 -52z m578 -642 c43 -59 79 -115 79 -123 0 -8 -58 -114 -129
-236 -70 -122 -292 -505 -491 -852 -200 -346 -441 -763 -534 -925 -94 -163
-506 -877 -916 -1588 -410 -710 -748 -1292 -752 -1292 -3 0 -27 37 -53 83 -26
45 -56 97 -67 114 -15 25 -16 32 -5 27 10 -4 54 65 160 248 79 139 255 444
389 678 135 234 334 580 443 770 109 190 255 444 325 565 70 121 277 481 460
800 183 319 485 843 670 1165 185 322 342 594 348 606 9 17 5 29 -24 75 -51
78 -1 20 97 -115z m-4444 -2154 c32 -56 34 -65 20 -80 -8 -9 -225 -384 -482
-832 -258 -448 -544 -948 -638 -1110 -93 -162 -244 -425 -335 -585 -91 -160
-170 -296 -177 -303 -10 -11 -22 -2 -64 49 -29 34 -50 68 -47 75 3 7 282 493
621 1081 340 587 712 1233 829 1436 187 325 213 365 224 350 7 -10 29 -46 49
-81z"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Logo
